<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <card>
          <h4 slot="header" class="card-title">{{$t('NuevaEntrada')}}</h4>
          <form>
            <div class="row">
              <div class="col-md-6">
                <base-input type="text" :label="$t('Nombre')" disabled="false" v-model="name"></base-input>
              </div>
              <div class="col-md-6">
                <base-input type="text" :label="$t('Apellido')" disabled="false" v-model="lastname"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input type="text" :label="$t('Email')" disabled="false" v-model="email"></base-input>
              </div>
              <div class="col-md-6">
                <base-input type="text" :label="$t('phone')" disabled="false" v-model="phone"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{$t('Mensaje')}}</label>
                  <textarea rows="5" disabled="false" class="form-control border-input" v-model="message"></textarea>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <router-link :to="'/admin/procesar-solicitud/' + id" class="btn btn-dark btn-fill py-2" v-if="request_type_id == 1 && active">
                  <span class="font-weight-bold px-3">
                    {{$t("Procesar")}}
                  </span>
              </router-link>
              <router-link :to="'/admin/contact/' + contact_id" class="btn btn-dark btn-fill py-2 mx-2" v-if="request_type_id == 2 && active">
                  <span class="font-weight-bold px-3">
                    {{$t("FichaContacto")}}
                  </span>
              </router-link>
            </div>
            <div class="clearfix"></div>
          </form>
        </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { apiCallSingle } from '../../mixins'
  
  export default {
    mixins: [apiCallSingle],
    watch: {
    $route() {
        if(this.id){
          this.fetch();
          this.onReadNotifications();
        }      
      }
    },
    data () {
      return {
        endpoint  : "request_services",
        name      : null,
        lastname  : null,
        phone     : null,
        email     : null,
        message   : null,
        request_type_id : null, 
        active    : false,
        contact_id : null,
      }
    },
    props : {
      id : String
    },
    methods: {
      loadData(){
          this.name     = this.data.contact.name;
          this.lastname = this.data.contact.lastname;
          this.phone    = this.data.contact.phone;
          this.email    = this.data.contact.email;
          this.message  = this.data.message;
          this.active   = this.data.active;
          this.request_type_id = this.data.request_type_id;
          this.contact_id = this.data.contact_id;
          this.onReadNotifications();
      },
      onReadNotifications(){
        this.$axios.put("/request_services/"+this.id , {status_read : 1})
          .then(() => {
            this.$store.dispatch('login/onLoadNotifications');
          })
      }
    }
    
  }

</script>
<style>

</style>
